// core imports
import React from 'react';

// component imports
import Card from './Card.js';

export default class Cards extends React.Component {
    render () {
        const cards = this.props.cards;
        const cardsOutput = [];

        for ( let i = 0; i < cards.length; ++i ) {
            cardsOutput.push( <Card key={i} name={cards[i].name} player={cards[i].player} /> );
        }

        return (
            <div className="cards">
                {cardsOutput}
            </div>
        );
    }
}