// core imports
import React from 'react';

export default class PlayControls extends React.Component {
    render () {
        if ( this.props.currentPlayer !== null ) {
            return (
                <div className="play-controls">
                    <button id="play-card" onClick={this.props.playCard}>Play Card</button>
                    <h2>Current Turn: {this.props.currentPlayer.name}</h2>
                    {this.props.captureMode &&
                        <div>
                            <h3>Capture Mode</h3>
                            <h4>{this.props.captureChances} Chances Remain</h4>
                        </div>
                    }
                </div>
            );
        }
        /*
        else {
            return (
                <div className="play-controls">
                    <button id="play-card" onClick={this.props.playCard}>Play Card</button>
                </div>
            );
        }
        */
    }
}