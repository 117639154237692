// core imports
import React from 'react';

export default class Stats extends React.Component {
    render () {
        const statsData = this.props.stats;
        const stats = statsData.map((stat, index) => {
            let output;

            if ( stat.hasOwnProperty('value') ) {
                output = <li key={index} className={stat.key}><span className="stat-title">{stat.label}:</span> <span className="stat-value">{stat.value}</span></li>;
            } else {
                output = <li key={index} className={stat.key}><span className="stat-title">{stat.label}</span></li>;
            }

            return output;
        });

        return (
            <section className="stats">
                <h2>Stats</h2>
                <ul className="stats-list">{stats}</ul>
            </section>
        );
    }
}