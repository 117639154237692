// core imports
import React from 'react';

// component imports
import PlayControls from './PlayControls.js';
import Cards from './Cards.js';

export default class PlayArea extends React.Component {
    render () {
        const playedDeck = this.props.playedDeck;
        const cards = playedDeck.cards;

        return (
            <section className="play-area"> 
                {this.props.winner === null &&
                <PlayControls 
                    playCard={this.props.playCard} 
                    currentPlayer={this.props.currentPlayer} 
                    captureMode={this.props.captureMode} 
                    captureChances={this.props.captureChances}
                />
                }
                <Cards cards={cards} />
            </section>
        );
    }
}