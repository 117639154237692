// core imports
import React from 'react';

export default class Card extends React.Component {
    render () {
        return (
            <div className="card">
                <div className="card-label">{this.props.name}</div>
                <div className="card-player">{this.props.player}</div>
            </div>
        );
    }
}