// core imports
import React from 'react';

// classes
import Deck from '../classes/Deck.js';
import Player from '../classes/Player.js';

// component imports
import Stats from './Stats.js';
import Players from './Players.js';
import PlayArea from './PlayArea.js';

export default class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [
                {
                    key: 'title',
                    label: 'Who Gets the Cheese?'
                },
                {
                    key: 'totalCards',
                    label: 'Total Cards',
                    value: 0
                },
                {
                    key: 'cardsPlayed',
                    label: 'Cards Played',
                    value: 0
                },
                {
                    key: 'remainingTokens',
                    label: 'Remaining Tokens',
                    value: 0
                },
                {
                    key: 'winner',
                    label: 'Winner',
                    value: null
                }
            ],
            players: [
                {
                    name: 'Philip'
                },
                {
                    name: 'Henry'
                }
            ],
            resources: {
                cards: {
                    resource: {
                        name: 'Cheese',
                        quantity: 66
                    },
                    captureResource: [
                        {
                            name: 'Mouse (1)',
                            chances: 1,
                            quantity: 8 
                        },
                        {
                            name: 'Mouse (2)',
                            chances: 2,
                            quantity: 8 
                        },
                        {
                            name: 'Mouse (3)',
                            chances: 3,
                            quantity: 8 
                        }
                    ],                   
                    captureToken: {
                        name: 'Kitty',
                        quantity: 16
                    } 
                },
                tokens: {
                    token: {
                        name: 'Kitty Pawn',
                        quantity: 6
                    },
                }
            },
            currentPlayer: null,
            playedDeck: new Deck(),
            captureMode: false,
            captureChances: 0,
        };

        const playerData = this.state.players;
        this.players = playerData.map((player, index) => {
            const thisPlayer = new Player(player.name);
            return thisPlayer;
        });
    }

    componentDidMount() {
        // assemble deck from card data, update stats, shuffle deck, and deal to players
        const stats = this.state.stats;
        const cards = this.state.resources.cards;
        const dealerDeck = new Deck();
        dealerDeck.addCards(cards);
        
        for ( let stat in stats ) {
            if ( stats[stat].key === 'totalCards' ) {
                stats[stat].value = dealerDeck.cards.length;
            }
            if ( stats[stat].key === 'remainingTokens' ) {
                stats[stat].value = this.state.resources.tokens.token.quantity;
            }
        }

        this.setState({
            stats: stats,
            currentPlayer: this.players[0]
        });

        dealerDeck.shuffle();
        dealerDeck.deal(this.players); 
    }

    checkForEnd() {
        if ( this.getStat('remainingTokens') === 0 ) {
            return true;
        }
        else {
            return false;
        }
    }

    getStat( key ) {
        for ( let i = 0; i < this.state.stats.length; ++i ) {
            if ( this.state.stats[i].key === key ) {
                return this.state.stats[i].value;
            }
        }
    }

    updateStat( key, value ) {
        let stats = this.state.stats;

        for ( let i = 0; i < stats.length; ++i ) {
            if ( stats[i].key === key ) {              
                stats[i].value = value;
                this.setState({
                    stats: stats
                });
                
                break;
            }
        }
    }

    determineWinner() {
        let highScore = 0;
        let highScorePlayerIndex = -1;
        let tie = false;

        for ( let i = 0; i < this.players.length; ++i ) {
            if ( this.players[i].score === highScore ) {
                tie = true;
                highScorePlayerIndex = -1;
            }
            else if ( this.players[i].score > highScore ) {
                tie = false;
                highScore = this.players[i].score;
                highScorePlayerIndex = i;
            }
        }

        if ( tie === false ) {
            return this.players[highScorePlayerIndex];
        }
        else {
            let highTokens = 0;
            let highTokensPlayerIndex = -1;
            tie = false;

            for ( let i = 0; i < this.players.length; ++i ) {
                if ( this.players[i].tokens === highTokens ) {
                    tie = true;
                    highTokensPlayerIndex = -1;
                }
                else if ( this.players[i].tokens > highTokens ) {
                    tie = false;
                    highScore = this.players[i].tokens;
                    highTokensPlayerIndex = i;
                }
            }

            if ( tie === false ) {
                return this.players[highTokensPlayerIndex];
            }
            else {
                return false;
            }
        }
    }
    
    nextPlayer( callback = null) {
        const numPlayers0 = this.players.length - 1;
        let currentPlayerIndex = this.players.indexOf(this.state.currentPlayer);
        
        if ( currentPlayerIndex < numPlayers0 ) {
            ++currentPlayerIndex;
        }
        else {
            currentPlayerIndex = 0;
        }

        this.setState({
            currentPlayer: this.players[currentPlayerIndex]
        }, callback );
    }

    checkPlayersForShuffle() {
        for ( const player of this.players ) {
            player.checkForShuffle();
        }
    }

    playCard() {
        const playedDeck = this.state.playedDeck;
        const playedCard = this.state.currentPlayer.playCard(playedDeck);
        const cardsPlayed = parseInt(this.getStat( 'cardsPlayed' ));
        const newCardsPlayed = parseInt(( cardsPlayed + 1));
        this.updateStat('cardsPlayed', newCardsPlayed);
        this.setState({
            playedDeck: playedDeck
        });

        console.log( this.state.currentPlayer.name + ' played ' + playedCard.type );
        
        switch ( playedCard.type ) {
            case 'captureResource':
                this.setState({
                    captureMode: true,
                    captureChances: playedCard.chances
                });   

                this.nextPlayer();

                break;

            case 'captureToken':
                if ( this.state.captureMode ) {
                    console.log( this.state.currentPlayer.name + ' captured the deck with a token' );
                    this.updateStat( 'remainingTokens', this.getStat( 'remainingTokens' ) - 1);
                    this.state.currentPlayer.collectDeck( playedDeck );
                    this.state.currentPlayer.addToken();

                    this.setState({
                        captureMode: false,
                        captureChances: 0
                    });
                }
                else {
                    this.nextPlayer();
                }
                
                break;

            case 'resource':
            default:
                if ( this.state.captureMode ) {
                    let captureChances = this.state.captureChances;
                    --captureChances;

                    if ( captureChances === 0 ) {
                        console.log( this.state.currentPlayer.name + ' played the final resource card' );                    

                        this.nextPlayer(() => {
                            console.log( this.state.currentPlayer.name + ' is going to collect the deck' );
                            this.state.currentPlayer.collectDeck( playedDeck );
                            this.setState({
                                captureMode: false,
                                captureChances: 0
                            });
                        });  
                    }
                    else {
                        this.setState({
                            captureChances: captureChances
                        });
                    }
                }
                else {
                    this.nextPlayer();
                }

                break;
        }

        if ( this.checkForEnd() ) {
            const winner = this.determineWinner();

            if ( winner ) {
                this.updateStat( 'winner', winner.name );
            }
            else {
                this.updateStat( 'winner', "It's a tie!" );
            }
        }
        else {
            this.checkPlayersForShuffle();
        }
    }

    render () {
        return (
            <div className="game">
                <div className="game-top">
                    <Stats stats={this.state.stats} />
                    <Players playerData={this.players} />
                </div>
                <div className="game-middle">
                    <PlayArea 
                        playCard={() => this.playCard()} 
                        currentPlayer={this.state.currentPlayer} 
                        playedDeck={this.state.playedDeck} 
                        captureMode={this.state.captureMode} 
                        captureChances={this.state.captureChances}
                        winner={this.getStat('winner')}
                    />
                </div>
                <div className="game-bottom">
                    <footer>Footer</footer>
                </div>
            </div>
        );
    }
}