import Deck from './Deck.js';

export default class Player {
	name;
	tokens;
	activeDeck;
	collectedDeck;
	
	constructor( name ) {
		this.name = name;
		this.tokens = 0;
		this.activeDeck = new Deck();
		this.collectedDeck = new Deck();
	}
	
	playCard( targetDeck ) {
		if ( this.activeDeck.cards.length > 0 ) {
			let playedCard = this.activeDeck.getCard();
			playedCard.player = this.name;
			targetDeck.addCard( playedCard );
			return playedCard;
		}
		// no cards
		else {
			return false;
		}
	}
	
	checkForShuffle() {
		if ( this.activeDeck.cards.length === 0 ) {
			if ( this.collectedDeck.cards.length > 0 ) {
				this.activeDeck.addDeck( this.collectedDeck );
				this.activeDeck.shuffle();
				console.log( this.name + ' shuffled their collected deck' );
			}
		}
	}
	
	collectDeck( deck ) {
		this.collectedDeck.addDeck( deck );
	}

	addToken() {
		++this.tokens;
	}
	
	get totalCards() {
		return this.activeDeck.cards.length + this.collectedDeck.cards.length;
	}

	get collectedCards() {
		return ( this.collectedDeck.cards.length > 0 ) ? this.collectedDeck.cards.length : 0;
	}

	get cardsInDeck() {
		return this.activeDeck.cards.length;
	}
	
	get score() {
		return this.totalCards + this.tokens;
	}
}