// core imports
import React from 'react';
import ReactDOM from 'react-dom/client';

// package feature imports
import WebFont from 'webfontloader';

// global style import
import './css/main.css';

// component imports
import Game from './components/Game.js';

WebFont.load({
  google: {
    families: ['Open Sans:300,300i,400,400i,700,700i']
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Game />); 