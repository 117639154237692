// core imports
import React from 'react';

// component imports
import Player from './Player.js';

export default class Players extends React.Component {
    render () {
        const playerData = this.props.playerData;
        
        const players = playerData.map( (player, index) => {
            return (
                <Player key={index} playerData={player} />
            );
        });

        return (
            <div className="players">{players}</div>
        );
    }
}