// core imports
import React from 'react';

export default class Player extends React.Component {
    render () {
        const playerData = this.props.playerData;

        return (
            <div className="player">
                <h2>{playerData.name}</h2>
                <ul className="player-stats">
                    <li><span className="stat-title">Total Cards:</span> <span className="stat-value">{playerData.totalCards}</span></li>
                    <li><span className="stat-title">Cards in Deck:</span> <span className="stat-value">{playerData.cardsInDeck}</span></li>
                    <li><span className="stat-title">Collected Cards:</span> <span className="stat-value">{playerData.collectedCards}</span></li>
                    <li><span className="stat-title">Tokens:</span> <span className="stat-value"></span>{playerData.tokens}</li>
                    <li><span className="stat-title">Score:</span> <span className="stat-value"></span>{playerData.score}</li>
                </ul>
            </div>
        );
    }
}