export default class Deck {
	cards = [];

	addCard( card ) {
		this.cards.push( card );
	}
	
	getCard() {
		return this.cards.pop();
	}
	
	shuffle() {
		let currentIndex = this.cards.length, temporaryValue, randomIndex;
	
		// While there remain elements to shuffle...
		while ( 0 !== currentIndex ) {
			// Pick a remaining element...
			randomIndex = Math.floor( Math.random() * currentIndex );
			currentIndex -= 1;
	
			// And swap it with the current element.
			temporaryValue = this.cards[currentIndex];
			this.cards[currentIndex] = this.cards[randomIndex];
			this.cards[randomIndex] = temporaryValue;
		}
	}
	
    addCards( cards ) {
        for ( const type in cards ) {
            // if it contains an array then we need to loop one level deeper
            if (cards[type].constructor === Array) {
                for ( let subCardIndex in cards[type] ) {
                    if ( typeof(cards[type][subCardIndex].quantity) === 'number' ) {
                        const quantity = parseInt( cards[type][subCardIndex].quantity );
        
                        let card = {
                            type: type
                        };
        
                        for ( let prop in cards[type][subCardIndex] ) {
                            if ( prop !== 'quantity' ) {
                                card[prop] = cards[type][subCardIndex][prop];
                            }
                        }
        
                        for ( let i = 0; i < quantity; ++i ) {
                            this.addCard(card);
                        }
                    }
                }
            }
            else {
                if ( typeof(cards[type].quantity) === 'number' ) {
                    const quantity = parseInt( cards[type].quantity );
    
                    let card = {
                        type: type
                    };
    
                    for ( let prop in cards[type] ) {
                        if ( prop !== 'quantity' ) {
                            card[prop] = cards[type][prop];
                        }
                    }
    
                    for ( let i = 0; i < quantity; ++i ) {
                        this.addCard(card);
                    }
                }
            }
        }
    }
    
    deal( players ) {
		this.cards.forEach( function( card, index ) {
			const playerIndex = index % players.length;
			players[playerIndex].activeDeck.addCard( card );
		});
		
		// we've dealt the cards so now this deck is empty
		this.cards = [];
	}
	
	addDeck( deck ) {
		this.cards = this.cards.concat( deck.cards );
		
		// we added the other deck to this one, so it has been collected
		deck.gotCollected();
	}
	
    // when a deck has been collected, it has been absorbed into another deck and doesn't exist separately any more
	gotCollected() {
		this.cards = [];
	}
}